export const EBtnToggle = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/btnToggle/index.vue' /* webpackChunkName: "components/e-btn-toggle" */).then(c => wrapFunctional(c.default || c))
export const EBtn = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/btn/index.vue' /* webpackChunkName: "components/e-btn" */).then(c => wrapFunctional(c.default || c))
export const ECheckbox = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/checkbox/index.vue' /* webpackChunkName: "components/e-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ECommonSelectOption = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/commonSelect/SelectOption.vue' /* webpackChunkName: "components/e-common-select-option" */).then(c => wrapFunctional(c.default || c))
export const ECommonSelect = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/commonSelect/index.vue' /* webpackChunkName: "components/e-common-select" */).then(c => wrapFunctional(c.default || c))
export const EDate = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/date/index.vue' /* webpackChunkName: "components/e-date" */).then(c => wrapFunctional(c.default || c))
export const EDatePicker = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/datePicker/index.vue' /* webpackChunkName: "components/e-date-picker" */).then(c => wrapFunctional(c.default || c))
export const EDatePickerMobile = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/datePicker/mobile.vue' /* webpackChunkName: "components/e-date-picker-mobile" */).then(c => wrapFunctional(c.default || c))
export const EDialog = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/dialog/index.vue' /* webpackChunkName: "components/e-dialog" */).then(c => wrapFunctional(c.default || c))
export const EDialogCard = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/dialogCard/index.vue' /* webpackChunkName: "components/e-dialog-card" */).then(c => wrapFunctional(c.default || c))
export const EExpansionItem = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/expansionItem/index.vue' /* webpackChunkName: "components/e-expansion-item" */).then(c => wrapFunctional(c.default || c))
export const EField = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/field/index.vue' /* webpackChunkName: "components/e-field" */).then(c => wrapFunctional(c.default || c))
export const EMenu = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/menu/index.vue' /* webpackChunkName: "components/e-menu" */).then(c => wrapFunctional(c.default || c))
export const ERadio = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/radio/index.vue' /* webpackChunkName: "components/e-radio" */).then(c => wrapFunctional(c.default || c))
export const ERadioGroup = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/radioGroup/index.vue' /* webpackChunkName: "components/e-radio-group" */).then(c => wrapFunctional(c.default || c))
export const ERange = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/range/index.vue' /* webpackChunkName: "components/e-range" */).then(c => wrapFunctional(c.default || c))
export const EScrollArea = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/scrollArea/index.vue' /* webpackChunkName: "components/e-scroll-area" */).then(c => wrapFunctional(c.default || c))
export const ESidebar = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/sidebar/index.vue' /* webpackChunkName: "components/e-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ESnackbar = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/snackbar/index.vue' /* webpackChunkName: "components/e-snackbar" */).then(c => wrapFunctional(c.default || c))
export const ETextField = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/textField/index.vue' /* webpackChunkName: "components/e-text-field" */).then(c => wrapFunctional(c.default || c))
export const EVirtualScroll = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/virtual-scroll/index.vue' /* webpackChunkName: "components/e-virtual-scroll" */).then(c => wrapFunctional(c.default || c))
export const EVirtualScrollArea = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/virtual-scroll/virtualScrollArea.vue' /* webpackChunkName: "components/e-virtual-scroll-area" */).then(c => wrapFunctional(c.default || c))
export const EDatePickerCalendarDate = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/datePicker/calendar/calendarDate.vue' /* webpackChunkName: "components/e-date-picker-calendar-date" */).then(c => wrapFunctional(c.default || c))
export const EDatePickerCalendarDateNav = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/datePicker/calendar/calendarDateNav.vue' /* webpackChunkName: "components/e-date-picker-calendar-date-nav" */).then(c => wrapFunctional(c.default || c))
export const EDatePickerCalendarMonth = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/datePicker/calendar/calendarMonth.vue' /* webpackChunkName: "components/e-date-picker-calendar-month" */).then(c => wrapFunctional(c.default || c))
export const EDatePickerCalendarYear = () => import('../../node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/datePicker/calendar/calendarYear.vue' /* webpackChunkName: "components/e-date-picker-calendar-year" */).then(c => wrapFunctional(c.default || c))
export const ModalNotify = () => import('../../components/ModalNotify.vue' /* webpackChunkName: "components/modal-notify" */).then(c => wrapFunctional(c.default || c))
export const AdvantagesFilter = () => import('../../components/advantagesFilter.vue' /* webpackChunkName: "components/advantages-filter" */).then(c => wrapFunctional(c.default || c))
export const Direction = () => import('../../components/direction.vue' /* webpackChunkName: "components/direction" */).then(c => wrapFunctional(c.default || c))
export const ExpansionItem = () => import('../../components/expansionItem.vue' /* webpackChunkName: "components/expansion-item" */).then(c => wrapFunctional(c.default || c))
export const FiltersContent = () => import('../../components/filtersContent.vue' /* webpackChunkName: "components/filters-content" */).then(c => wrapFunctional(c.default || c))
export const GrecaptchaTerms = () => import('../../components/grecaptchaTerms.vue' /* webpackChunkName: "components/grecaptcha-terms" */).then(c => wrapFunctional(c.default || c))
export const LazyImg = () => import('../../components/lazy-img.vue' /* webpackChunkName: "components/lazy-img" */).then(c => wrapFunctional(c.default || c))
export const MainCourses = () => import('../../components/mainCourses.vue' /* webpackChunkName: "components/main-courses" */).then(c => wrapFunctional(c.default || c))
export const MainFilter = () => import('../../components/mainFilter.vue' /* webpackChunkName: "components/main-filter" */).then(c => wrapFunctional(c.default || c))
export const MainSearch = () => import('../../components/mainSearch.vue' /* webpackChunkName: "components/main-search" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PortalDialog = () => import('../../components/portalDialog.vue' /* webpackChunkName: "components/portal-dialog" */).then(c => wrapFunctional(c.default || c))
export const PortalDialogTarget = () => import('../../components/portalDialogTarget.vue' /* webpackChunkName: "components/portal-dialog-target" */).then(c => wrapFunctional(c.default || c))
export const ReviewsSection = () => import('../../components/reviewsSection.vue' /* webpackChunkName: "components/reviews-section" */).then(c => wrapFunctional(c.default || c))
export const TeachersSlider = () => import('../../components/teachersSlider.vue' /* webpackChunkName: "components/teachers-slider" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const TopicsFilter = () => import('../../components/topicsFilter.vue' /* webpackChunkName: "components/topics-filter" */).then(c => wrapFunctional(c.default || c))
export const TopicsFilterItem = () => import('../../components/topicsFilterItem.vue' /* webpackChunkName: "components/topics-filter-item" */).then(c => wrapFunctional(c.default || c))
export const MoreButton = () => import('../../components/MoreButton/index.vue' /* webpackChunkName: "components/more-button" */).then(c => wrapFunctional(c.default || c))
export const AbTestQuery = () => import('../../components/ab/testQuery.vue' /* webpackChunkName: "components/ab-test-query" */).then(c => wrapFunctional(c.default || c))
export const AbTestStore = () => import('../../components/ab/testStore.vue' /* webpackChunkName: "components/ab-test-store" */).then(c => wrapFunctional(c.default || c))
export const AbVariantQuery = () => import('../../components/ab/variantQuery.vue' /* webpackChunkName: "components/ab-variant-query" */).then(c => wrapFunctional(c.default || c))
export const AbVariantStore = () => import('../../components/ab/variantStore.vue' /* webpackChunkName: "components/ab-variant-store" */).then(c => wrapFunctional(c.default || c))
export const AutoscaleLazyImg = () => import('../../components/autoscale/lazyImg.vue' /* webpackChunkName: "components/autoscale-lazy-img" */).then(c => wrapFunctional(c.default || c))
export const AutoscaleLoader = () => import('../../components/autoscale/loader.vue' /* webpackChunkName: "components/autoscale-loader" */).then(c => wrapFunctional(c.default || c))
export const AutoscalePageLayout = () => import('../../components/autoscale/pageLayout.vue' /* webpackChunkName: "components/autoscale-page-layout" */).then(c => wrapFunctional(c.default || c))
export const BlogBreadcrumbs = () => import('../../components/blog/blogBreadcrumbs.vue' /* webpackChunkName: "components/blog-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const BlogPostsListHeader = () => import('../../components/blog/blogPostsListHeader.vue' /* webpackChunkName: "components/blog-posts-list-header" */).then(c => wrapFunctional(c.default || c))
export const BlogPostsListItem = () => import('../../components/blog/blogPostsListItem.vue' /* webpackChunkName: "components/blog-posts-list-item" */).then(c => wrapFunctional(c.default || c))
export const BlogPostsListNavigation = () => import('../../components/blog/blogPostsListNavigation.vue' /* webpackChunkName: "components/blog-posts-list-navigation" */).then(c => wrapFunctional(c.default || c))
export const CommonBaseImage = () => import('../../components/common/BaseImage.vue' /* webpackChunkName: "components/common-base-image" */).then(c => wrapFunctional(c.default || c))
export const CommonBaseSkeleton = () => import('../../components/common/BaseSkeleton.vue' /* webpackChunkName: "components/common-base-skeleton" */).then(c => wrapFunctional(c.default || c))
export const CommonBreadcrumbs = () => import('../../components/common/breadcrumbs.vue' /* webpackChunkName: "components/common-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const CommonCheckbox = () => import('../../components/common/checkbox.vue' /* webpackChunkName: "components/common-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CommonContentList = () => import('../../components/common/contentList.vue' /* webpackChunkName: "components/common-content-list" */).then(c => wrapFunctional(c.default || c))
export const CommonContentListV2 = () => import('../../components/common/contentListV2.vue' /* webpackChunkName: "components/common-content-list-v2" */).then(c => wrapFunctional(c.default || c))
export const CommonHideLines = () => import('../../components/common/hideLines.vue' /* webpackChunkName: "components/common-hide-lines" */).then(c => wrapFunctional(c.default || c))
export const CommonHintEmail = () => import('../../components/common/hintEmail.vue' /* webpackChunkName: "components/common-hint-email" */).then(c => wrapFunctional(c.default || c))
export const CommonNoScroll = () => import('../../components/common/noScroll.vue' /* webpackChunkName: "components/common-no-scroll" */).then(c => wrapFunctional(c.default || c))
export const CommonPhone = () => import('../../components/common/phone.vue' /* webpackChunkName: "components/common-phone" */).then(c => wrapFunctional(c.default || c))
export const CommonPrivacyPolicy = () => import('../../components/common/privacyPolicy.vue' /* webpackChunkName: "components/common-privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const CommonPrivacyPolicyText = () => import('../../components/common/privacyPolicyText.vue' /* webpackChunkName: "components/common-privacy-policy-text" */).then(c => wrapFunctional(c.default || c))
export const CommonSlideItems = () => import('../../components/common/slideItems.vue' /* webpackChunkName: "components/common-slide-items" */).then(c => wrapFunctional(c.default || c))
export const CommonSlider = () => import('../../components/common/slider.vue' /* webpackChunkName: "components/common-slider" */).then(c => wrapFunctional(c.default || c))
export const CoursesCourseCard = () => import('../../components/courses/courseCard.vue' /* webpackChunkName: "components/courses-course-card" */).then(c => wrapFunctional(c.default || c))
export const CoursesCards = () => import('../../components/courses/coursesCards.vue' /* webpackChunkName: "components/courses-cards" */).then(c => wrapFunctional(c.default || c))
export const CoursesFilter = () => import('../../components/courses/coursesFilter.vue' /* webpackChunkName: "components/courses-filter" */).then(c => wrapFunctional(c.default || c))
export const CoursesFilterBody = () => import('../../components/courses/coursesFilterBody.vue' /* webpackChunkName: "components/courses-filter-body" */).then(c => wrapFunctional(c.default || c))
export const CoursesIntro = () => import('../../components/courses/coursesIntro.vue' /* webpackChunkName: "components/courses-intro" */).then(c => wrapFunctional(c.default || c))
export const CoursesMobileFilterContent = () => import('../../components/courses/coursesMobileFilterContent.vue' /* webpackChunkName: "components/courses-mobile-filter-content" */).then(c => wrapFunctional(c.default || c))
export const CoursesMobileFitler = () => import('../../components/courses/coursesMobileFitler.vue' /* webpackChunkName: "components/courses-mobile-fitler" */).then(c => wrapFunctional(c.default || c))
export const CoursesSeoDescription = () => import('../../components/courses/coursesSeoDescription.vue' /* webpackChunkName: "components/courses-seo-description" */).then(c => wrapFunctional(c.default || c))
export const D0822VideoPresentation = () => import('../../components/d0822/VideoPresentation.vue' /* webpackChunkName: "components/d0822-video-presentation" */).then(c => wrapFunctional(c.default || c))
export const D0822About = () => import('../../components/d0822/about.vue' /* webpackChunkName: "components/d0822-about" */).then(c => wrapFunctional(c.default || c))
export const D0822BestCourse = () => import('../../components/d0822/bestCourse.vue' /* webpackChunkName: "components/d0822-best-course" */).then(c => wrapFunctional(c.default || c))
export const D0822Blog = () => import('../../components/d0822/blog.vue' /* webpackChunkName: "components/d0822-blog" */).then(c => wrapFunctional(c.default || c))
export const D0822Countries = () => import('../../components/d0822/countries.vue' /* webpackChunkName: "components/d0822-countries" */).then(c => wrapFunctional(c.default || c))
export const D0822CoursesAndProfessions = () => import('../../components/d0822/coursesAndProfessions.vue' /* webpackChunkName: "components/d0822-courses-and-professions" */).then(c => wrapFunctional(c.default || c))
export const D0822Footer = () => import('../../components/d0822/footer.vue' /* webpackChunkName: "components/d0822-footer" */).then(c => wrapFunctional(c.default || c))
export const D0822Form = () => import('../../components/d0822/form.vue' /* webpackChunkName: "components/d0822-form" */).then(c => wrapFunctional(c.default || c))
export const D0822Header = () => import('../../components/d0822/header.vue' /* webpackChunkName: "components/d0822-header" */).then(c => wrapFunctional(c.default || c))
export const D0822JobGuaranteePolicy = () => import('../../components/d0822/jobGuaranteePolicy.vue' /* webpackChunkName: "components/d0822-job-guarantee-policy" */).then(c => wrapFunctional(c.default || c))
export const D0822Main = () => import('../../components/d0822/main.vue' /* webpackChunkName: "components/d0822-main" */).then(c => wrapFunctional(c.default || c))
export const D0822Partners = () => import('../../components/d0822/partners.vue' /* webpackChunkName: "components/d0822-partners" */).then(c => wrapFunctional(c.default || c))
export const D0822Popular = () => import('../../components/d0822/popular.vue' /* webpackChunkName: "components/d0822-popular" */).then(c => wrapFunctional(c.default || c))
export const D0822Professors = () => import('../../components/d0822/professors.vue' /* webpackChunkName: "components/d0822-professors" */).then(c => wrapFunctional(c.default || c))
export const D0822Studio = () => import('../../components/d0822/studio.vue' /* webpackChunkName: "components/d0822-studio" */).then(c => wrapFunctional(c.default || c))
export const D0822TextReviews = () => import('../../components/d0822/textReviews.vue' /* webpackChunkName: "components/d0822-text-reviews" */).then(c => wrapFunctional(c.default || c))
export const D0822VideoReviews = () => import('../../components/d0822/videoReviews.vue' /* webpackChunkName: "components/d0822-video-reviews" */).then(c => wrapFunctional(c.default || c))
export const D0822Webinars = () => import('../../components/d0822/webinars.vue' /* webpackChunkName: "components/d0822-webinars" */).then(c => wrapFunctional(c.default || c))
export const EbacUiKitRange = () => import('../../components/ebac-ui-kit/range.vue' /* webpackChunkName: "components/ebac-ui-kit-range" */).then(c => wrapFunctional(c.default || c))
export const EventSaleBadge = () => import('../../components/eventSale/badge.vue' /* webpackChunkName: "components/event-sale-badge" */).then(c => wrapFunctional(c.default || c))
export const EventSaleCard = () => import('../../components/eventSale/card.vue' /* webpackChunkName: "components/event-sale-card" */).then(c => wrapFunctional(c.default || c))
export const EventSaleForm = () => import('../../components/eventSale/form.vue' /* webpackChunkName: "components/event-sale-form" */).then(c => wrapFunctional(c.default || c))
export const EventSaleMessage = () => import('../../components/eventSale/message.vue' /* webpackChunkName: "components/event-sale-message" */).then(c => wrapFunctional(c.default || c))
export const EventSaleTimer = () => import('../../components/eventSale/timer.vue' /* webpackChunkName: "components/event-sale-timer" */).then(c => wrapFunctional(c.default || c))
export const EventsCard = () => import('../../components/events/card.vue' /* webpackChunkName: "components/events-card" */).then(c => wrapFunctional(c.default || c))
export const EventsSpeakersPreview = () => import('../../components/events/speakersPreview.vue' /* webpackChunkName: "components/events-speakers-preview" */).then(c => wrapFunctional(c.default || c))
export const OffersCourse = () => import('../../components/offers/course.vue' /* webpackChunkName: "components/offers-course" */).then(c => wrapFunctional(c.default || c))
export const OffersCourses = () => import('../../components/offers/courses.vue' /* webpackChunkName: "components/offers-courses" */).then(c => wrapFunctional(c.default || c))
export const OffersCoursesSkeleton = () => import('../../components/offers/coursesSkeleton.vue' /* webpackChunkName: "components/offers-courses-skeleton" */).then(c => wrapFunctional(c.default || c))
export const OffersFlywirePaymentForm = () => import('../../components/offers/flywirePaymentForm.vue' /* webpackChunkName: "components/offers-flywire-payment-form" */).then(c => wrapFunctional(c.default || c))
export const OffersLoanRequestContactInfo = () => import('../../components/offers/loanRequestContactInfo.vue' /* webpackChunkName: "components/offers-loan-request-contact-info" */).then(c => wrapFunctional(c.default || c))
export const OffersLoanRequestForm = () => import('../../components/offers/loanRequestForm.vue' /* webpackChunkName: "components/offers-loan-request-form" */).then(c => wrapFunctional(c.default || c))
export const OffersPaymentCard = () => import('../../components/offers/paymentCard.vue' /* webpackChunkName: "components/offers-payment-card" */).then(c => wrapFunctional(c.default || c))
export const OffersPaymentCardSkeleton = () => import('../../components/offers/paymentCardSkeleton.vue' /* webpackChunkName: "components/offers-payment-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const OffersPaymentLink = () => import('../../components/offers/paymentLink.vue' /* webpackChunkName: "components/offers-payment-link" */).then(c => wrapFunctional(c.default || c))
export const OffersPaymentLinks = () => import('../../components/offers/paymentLinks.vue' /* webpackChunkName: "components/offers-payment-links" */).then(c => wrapFunctional(c.default || c))
export const SaleDialog = () => import('../../components/sale/dialog.vue' /* webpackChunkName: "components/sale-dialog" */).then(c => wrapFunctional(c.default || c))
export const SaleDiscount = () => import('../../components/sale/discount.vue' /* webpackChunkName: "components/sale-discount" */).then(c => wrapFunctional(c.default || c))
export const SaleTimer = () => import('../../components/sale/timer.vue' /* webpackChunkName: "components/sale-timer" */).then(c => wrapFunctional(c.default || c))
export const SaleBanner = () => import('../../components/saleBanner/index.vue' /* webpackChunkName: "components/sale-banner" */).then(c => wrapFunctional(c.default || c))
export const SaleBannerV2 = () => import('../../components/saleBannerV2/index.vue' /* webpackChunkName: "components/sale-banner-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUs = () => import('../../components/sections/about-us.vue' /* webpackChunkName: "components/sections-about-us" */).then(c => wrapFunctional(c.default || c))
export const SectionsPartners = () => import('../../components/sections/partners.vue' /* webpackChunkName: "components/sections-partners" */).then(c => wrapFunctional(c.default || c))
export const SectionsPlan = () => import('../../components/sections/plan.vue' /* webpackChunkName: "components/sections-plan" */).then(c => wrapFunctional(c.default || c))
export const SectionsReviews = () => import('../../components/sections/reviews.vue' /* webpackChunkName: "components/sections-reviews" */).then(c => wrapFunctional(c.default || c))
export const SobreNewHeadline = () => import('../../components/sobre/NewHeadline.vue' /* webpackChunkName: "components/sobre-new-headline" */).then(c => wrapFunctional(c.default || c))
export const SobreNewNavigation = () => import('../../components/sobre/NewNavigation.vue' /* webpackChunkName: "components/sobre-new-navigation" */).then(c => wrapFunctional(c.default || c))
export const SobreNewNavigationButton = () => import('../../components/sobre/NewNavigationButton.vue' /* webpackChunkName: "components/sobre-new-navigation-button" */).then(c => wrapFunctional(c.default || c))
export const SobreNewTabs = () => import('../../components/sobre/NewTabs.vue' /* webpackChunkName: "components/sobre-new-tabs" */).then(c => wrapFunctional(c.default || c))
export const SobreAdditionalNavigation = () => import('../../components/sobre/additionalNavigation.vue' /* webpackChunkName: "components/sobre-additional-navigation" */).then(c => wrapFunctional(c.default || c))
export const SobreDirections = () => import('../../components/sobre/directions.vue' /* webpackChunkName: "components/sobre-directions" */).then(c => wrapFunctional(c.default || c))
export const SobreDirectionsSelect = () => import('../../components/sobre/directionsSelect.vue' /* webpackChunkName: "components/sobre-directions-select" */).then(c => wrapFunctional(c.default || c))
export const SobreFirstSection = () => import('../../components/sobre/firstSection.vue' /* webpackChunkName: "components/sobre-first-section" */).then(c => wrapFunctional(c.default || c))
export const SobreListNavigation = () => import('../../components/sobre/listNavigation.vue' /* webpackChunkName: "components/sobre-list-navigation" */).then(c => wrapFunctional(c.default || c))
export const SobreMainNavigation = () => import('../../components/sobre/mainNavigation.vue' /* webpackChunkName: "components/sobre-main-navigation" */).then(c => wrapFunctional(c.default || c))
export const SobreVacanciesEmail = () => import('../../components/sobre/vacanciesEmail.vue' /* webpackChunkName: "components/sobre-vacancies-email" */).then(c => wrapFunctional(c.default || c))
export const UiKitAccordion = () => import('../../components/ui-kit/accordion.vue' /* webpackChunkName: "components/ui-kit-accordion" */).then(c => wrapFunctional(c.default || c))
export const UiKitBadge = () => import('../../components/ui-kit/badge.vue' /* webpackChunkName: "components/ui-kit-badge" */).then(c => wrapFunctional(c.default || c))
export const UiKitButton = () => import('../../components/ui-kit/button.vue' /* webpackChunkName: "components/ui-kit-button" */).then(c => wrapFunctional(c.default || c))
export const UiKitCheckbox = () => import('../../components/ui-kit/checkbox.vue' /* webpackChunkName: "components/ui-kit-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiKitChip = () => import('../../components/ui-kit/chip.vue' /* webpackChunkName: "components/ui-kit-chip" */).then(c => wrapFunctional(c.default || c))
export const UiKitFooter = () => import('../../components/ui-kit/footer.vue' /* webpackChunkName: "components/ui-kit-footer" */).then(c => wrapFunctional(c.default || c))
export const UiKitHeader = () => import('../../components/ui-kit/header.vue' /* webpackChunkName: "components/ui-kit-header" */).then(c => wrapFunctional(c.default || c))
export const UiKitInput = () => import('../../components/ui-kit/input.vue' /* webpackChunkName: "components/ui-kit-input" */).then(c => wrapFunctional(c.default || c))
export const UiKitPhone = () => import('../../components/ui-kit/phone.vue' /* webpackChunkName: "components/ui-kit-phone" */).then(c => wrapFunctional(c.default || c))
export const UiKitPopup = () => import('../../components/ui-kit/popup.vue' /* webpackChunkName: "components/ui-kit-popup" */).then(c => wrapFunctional(c.default || c))
export const UiKitRadiobutton = () => import('../../components/ui-kit/radiobutton.vue' /* webpackChunkName: "components/ui-kit-radiobutton" */).then(c => wrapFunctional(c.default || c))
export const UiKitRangeSlider = () => import('../../components/ui-kit/range-slider.vue' /* webpackChunkName: "components/ui-kit-range-slider" */).then(c => wrapFunctional(c.default || c))
export const UiKitSelect = () => import('../../components/ui-kit/select.vue' /* webpackChunkName: "components/ui-kit-select" */).then(c => wrapFunctional(c.default || c))
export const UserAvatar = () => import('../../components/userAvatar/index.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c))
export const WhatsappWidget = () => import('../../components/whatsappWidget/index.vue' /* webpackChunkName: "components/whatsapp-widget" */).then(c => wrapFunctional(c.default || c))
export const CoursesBlackFridayBanner = () => import('../../components/courses/black-friday/banner.vue' /* webpackChunkName: "components/courses-black-friday-banner" */).then(c => wrapFunctional(c.default || c))
export const CoursesBlackFridayTicker = () => import('../../components/courses/black-friday/ticker.vue' /* webpackChunkName: "components/courses-black-friday-ticker" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsCountryCard = () => import('../../components/d0822/components/countryCard.vue' /* webpackChunkName: "components/d0822-components-country-card" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsMultiselect = () => import('../../components/d0822/components/multiselect.vue' /* webpackChunkName: "components/d0822-components-multiselect" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsPaddedText = () => import('../../components/d0822/components/paddedText.vue' /* webpackChunkName: "components/d0822-components-padded-text" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsSpeechBubble = () => import('../../components/d0822/components/speechBubble.vue' /* webpackChunkName: "components/d0822-components-speech-bubble" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsTextReview = () => import('../../components/d0822/components/textReview.vue' /* webpackChunkName: "components/d0822-components-text-review" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsWebinar = () => import('../../components/d0822/components/webinar.vue' /* webpackChunkName: "components/d0822-components-webinar" */).then(c => wrapFunctional(c.default || c))
export const D0822FieldsButton = () => import('../../components/d0822/fields/button.vue' /* webpackChunkName: "components/d0822-fields-button" */).then(c => wrapFunctional(c.default || c))
export const D0822FieldsButtonToggle = () => import('../../components/d0822/fields/buttonToggle.vue' /* webpackChunkName: "components/d0822-fields-button-toggle" */).then(c => wrapFunctional(c.default || c))
export const D0822FieldsRadioCheck = () => import('../../components/d0822/fields/radioCheck.vue' /* webpackChunkName: "components/d0822-fields-radio-check" */).then(c => wrapFunctional(c.default || c))
export const D0822FieldsRadioCheckGroup = () => import('../../components/d0822/fields/radioCheckGroup.vue' /* webpackChunkName: "components/d0822-fields-radio-check-group" */).then(c => wrapFunctional(c.default || c))
export const D0822FieldsSelectField = () => import('../../components/d0822/fields/selectField.vue' /* webpackChunkName: "components/d0822-fields-select-field" */).then(c => wrapFunctional(c.default || c))
export const D0822FieldsSelectFieldMobileMode = () => import('../../components/d0822/fields/selectFieldMobileMode.vue' /* webpackChunkName: "components/d0822-fields-select-field-mobile-mode" */).then(c => wrapFunctional(c.default || c))
export const D0822FieldsSlider = () => import('../../components/d0822/fields/slider.vue' /* webpackChunkName: "components/d0822-fields-slider" */).then(c => wrapFunctional(c.default || c))
export const D0822FieldsTextField = () => import('../../components/d0822/fields/textField.vue' /* webpackChunkName: "components/d0822-fields-text-field" */).then(c => wrapFunctional(c.default || c))
export const EbacUiKitCommonSelect = () => import('../../components/ebac-ui-kit/commonSelect/commonSelect.vue' /* webpackChunkName: "components/ebac-ui-kit-common-select" */).then(c => wrapFunctional(c.default || c))
export const EbacUiKitUtilsFormat = () => import('../../components/ebac-ui-kit/utils/format.js' /* webpackChunkName: "components/ebac-ui-kit-utils-format" */).then(c => wrapFunctional(c.default || c))
export const EbacUiKitUtilsIsServer = () => import('../../components/ebac-ui-kit/utils/isServer.js' /* webpackChunkName: "components/ebac-ui-kit-utils-is-server" */).then(c => wrapFunctional(c.default || c))
export const MainPageMainFormSection = () => import('../../components/mainPage/mainFormSection/index.vue' /* webpackChunkName: "components/main-page-main-form-section" */).then(c => wrapFunctional(c.default || c))
export const TemplateAboutEBAC = () => import('../../components/template/aboutEBAC/index.vue' /* webpackChunkName: "components/template-about-e-b-a-c" */).then(c => wrapFunctional(c.default || c))
export const TemplateAboutWhatIndex = () => import('../../components/template/aboutWhat/_index.vue' /* webpackChunkName: "components/template-about-what-index" */).then(c => wrapFunctional(c.default || c))
export const TemplateAboutWhat = () => import('../../components/template/aboutWhat/index.vue' /* webpackChunkName: "components/template-about-what" */).then(c => wrapFunctional(c.default || c))
export const TemplateAboutWhatPortfolioItem = () => import('../../components/template/aboutWhat/portfolioItem.vue' /* webpackChunkName: "components/template-about-what-portfolio-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateAboutWhatTopicItem = () => import('../../components/template/aboutWhat/topicItem.vue' /* webpackChunkName: "components/template-about-what-topic-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateBenefits = () => import('../../components/template/benefits/index.vue' /* webpackChunkName: "components/template-benefits" */).then(c => wrapFunctional(c.default || c))
export const TemplateBenefitsListItem = () => import('../../components/template/benefits/listItem.vue' /* webpackChunkName: "components/template-benefits-list-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateBreadcrumbs = () => import('../../components/template/breadcrumbs/index.vue' /* webpackChunkName: "components/template-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const TemplateCareerPlan = () => import('../../components/template/careerCenter/careerPlan.vue' /* webpackChunkName: "components/template-career-plan" */).then(c => wrapFunctional(c.default || c))
export const TemplateCareerCenterHelpItem = () => import('../../components/template/careerCenter/helpItem.vue' /* webpackChunkName: "components/template-career-center-help-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateCareerCenter = () => import('../../components/template/careerCenter/index.vue' /* webpackChunkName: "components/template-career-center" */).then(c => wrapFunctional(c.default || c))
export const TemplateCourseProgram = () => import('../../components/template/courseProgram/index.vue' /* webpackChunkName: "components/template-course-program" */).then(c => wrapFunctional(c.default || c))
export const TemplateCourseRoutineItem = () => import('../../components/template/courseRoutine/courseRoutineItem.vue' /* webpackChunkName: "components/template-course-routine-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateCourseRoutine = () => import('../../components/template/courseRoutine/index.vue' /* webpackChunkName: "components/template-course-routine" */).then(c => wrapFunctional(c.default || c))
export const TemplateCourseRoutineLifeTimeAccess = () => import('../../components/template/courseRoutine/lifeTimeAccess.vue' /* webpackChunkName: "components/template-course-routine-life-time-access" */).then(c => wrapFunctional(c.default || c))
export const TemplateCourseTeacher = () => import('../../components/template/courseTeacher/index.vue' /* webpackChunkName: "components/template-course-teacher" */).then(c => wrapFunctional(c.default || c))
export const TemplateCourseTeacherItem = () => import('../../components/template/courseTeacher/teacherItem.vue' /* webpackChunkName: "components/template-course-teacher-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateCourseTeacherSkill = () => import('../../components/template/courseTeacher/teacherSkill.vue' /* webpackChunkName: "components/template-course-teacher-skill" */).then(c => wrapFunctional(c.default || c))
export const TemplateFaq = () => import('../../components/template/faq/index.vue' /* webpackChunkName: "components/template-faq" */).then(c => wrapFunctional(c.default || c))
export const TemplateFirstBlock = () => import('../../components/template/firstBlock/index.vue' /* webpackChunkName: "components/template-first-block" */).then(c => wrapFunctional(c.default || c))
export const TemplateFloatingFormButtonBlock = () => import('../../components/template/floatingFormButtonBlock/index.vue' /* webpackChunkName: "components/template-floating-form-button-block" */).then(c => wrapFunctional(c.default || c))
export const TemplateForm = () => import('../../components/template/form/index.vue' /* webpackChunkName: "components/template-form" */).then(c => wrapFunctional(c.default || c))
export const TemplateFormPromoCode = () => import('../../components/template/form/promoCode.vue' /* webpackChunkName: "components/template-form-promo-code" */).then(c => wrapFunctional(c.default || c))
export const TemplateGallery = () => import('../../components/template/gallery/index.vue' /* webpackChunkName: "components/template-gallery" */).then(c => wrapFunctional(c.default || c))
export const TemplateGalleryItem = () => import('../../components/template/gallery/item.vue' /* webpackChunkName: "components/template-gallery-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateGift = () => import('../../components/template/gift/index.vue' /* webpackChunkName: "components/template-gift" */).then(c => wrapFunctional(c.default || c))
export const TemplateHighDemand = () => import('../../components/template/highDemand/index.vue' /* webpackChunkName: "components/template-high-demand" */).then(c => wrapFunctional(c.default || c))
export const TemplateHighDemandItem = () => import('../../components/template/highDemand/item.vue' /* webpackChunkName: "components/template-high-demand-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateOurTeachers = () => import('../../components/template/ourTeachers/index.vue' /* webpackChunkName: "components/template-our-teachers" */).then(c => wrapFunctional(c.default || c))
export const TemplateOurTeachersTeacher = () => import('../../components/template/ourTeachers/teacher.vue' /* webpackChunkName: "components/template-our-teachers-teacher" */).then(c => wrapFunctional(c.default || c))
export const TemplatePartnerBlock = () => import('../../components/template/partnerBlock/index.vue' /* webpackChunkName: "components/template-partner-block" */).then(c => wrapFunctional(c.default || c))
export const TemplatePaymentResultSuccess = () => import('../../components/template/paymentResult/success.vue' /* webpackChunkName: "components/template-payment-result-success" */).then(c => wrapFunctional(c.default || c))
export const TemplateRecommend = () => import('../../components/template/recommend/index.vue' /* webpackChunkName: "components/template-recommend" */).then(c => wrapFunctional(c.default || c))
export const TemplateSalary = () => import('../../components/template/salary/index.vue' /* webpackChunkName: "components/template-salary" */).then(c => wrapFunctional(c.default || c))
export const TemplateSalaryPositionItem = () => import('../../components/template/salary/positionItem.vue' /* webpackChunkName: "components/template-salary-position-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateSummary = () => import('../../components/template/summary/index.vue' /* webpackChunkName: "components/template-summary" */).then(c => wrapFunctional(c.default || c))
export const TemplateSummarySkillItem = () => import('../../components/template/summary/skillItem.vue' /* webpackChunkName: "components/template-summary-skill-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateSummaryToolItem = () => import('../../components/template/summary/toolItem.vue' /* webpackChunkName: "components/template-summary-tool-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateTargetAudience = () => import('../../components/template/targetAudience/index.vue' /* webpackChunkName: "components/template-target-audience" */).then(c => wrapFunctional(c.default || c))
export const TemplateTargetAudienceItem = () => import('../../components/template/targetAudience/targetAudienceItem.vue' /* webpackChunkName: "components/template-target-audience-item" */).then(c => wrapFunctional(c.default || c))
export const TemplateThankYouPageNew = () => import('../../components/template/thankYouPageNew/index.vue' /* webpackChunkName: "components/template-thank-you-page-new" */).then(c => wrapFunctional(c.default || c))
export const TemplateThankYouPageTildaDesign = () => import('../../components/template/thankYouPageTildaDesign/index.vue' /* webpackChunkName: "components/template-thank-you-page-tilda-design" */).then(c => wrapFunctional(c.default || c))
export const TemplateTrailer = () => import('../../components/template/trailer/index.vue' /* webpackChunkName: "components/template-trailer" */).then(c => wrapFunctional(c.default || c))
export const UiKitSliderLeftArrow = () => import('../../components/ui-kit/slider/leftArrow.vue' /* webpackChunkName: "components/ui-kit-slider-left-arrow" */).then(c => wrapFunctional(c.default || c))
export const UiKitSliderRightArrow = () => import('../../components/ui-kit/slider/rightArrow.vue' /* webpackChunkName: "components/ui-kit-slider-right-arrow" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsInnerLoader = () => import('../../components/d0822/components/innerLoader/index.vue' /* webpackChunkName: "components/d0822-components-inner-loader" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsPaymentSystemItem = () => import('../../components/d0822/components/paymentSystems/paymentSystemItem.vue' /* webpackChunkName: "components/d0822-components-payment-system-item" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsPaymentSystemList = () => import('../../components/d0822/components/paymentSystems/paymentSystemList.vue' /* webpackChunkName: "components/d0822-components-payment-system-list" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsTopSale = () => import('../../components/d0822/components/topSale/index.vue' /* webpackChunkName: "components/d0822-components-top-sale" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsTopSaleCursos = () => import('../../components/d0822/components/topSale/topSaleCursos.vue' /* webpackChunkName: "components/d0822-components-top-sale-cursos" */).then(c => wrapFunctional(c.default || c))
export const D0822ComponentsTopSaleSale = () => import('../../components/d0822/components/topSale/topSaleSale.vue' /* webpackChunkName: "components/d0822-components-top-sale-sale" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
